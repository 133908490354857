import $, { error } from 'jquery';
import ons from 'onsenui';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';
import { DEBUG, LANG, showToast, onPullHookChangeState, getParameterByName, getCookie, setCookie } from  '../../src/js/script.js';

import { AuthClient } from "@dfinity/auth-client";
import { Principal } from "@dfinity/principal";
import { createActor, canisterId } from "../../icp-buyer-seller-contract-backend"

const { v4: uuidv4 } = require('uuid');
import fs from 'fs';
import axios from 'axios';
import i18next from 'i18next';
import introJs from 'intro.js';

const dotenv = require('dotenv');
dotenv.config();

const APP_URL = process.env.APP_URL;
const BEARER_TOKEN = "EAAHyZCjZAMC7oBAGjzmDxqVxJq77BrhV7TIqLMLZCLV7UaB69OcD2nssv9y9bpCmMA0SMFkF91zF93PnrhPInAQeWSkbSmyEr8ZC8fdNbQ5Fxllu3ZAzKsMqjMv3MaXRSwbf7sklghONHSKzu4bJ9unlSnfBZBhZCqUKyUyTUmy1IhJzxFFyezccNzU0bBkm5qLA4gYC4DXc2y0qdFjGSTD";

const CANISTER_ID = (true || process.env.NODE_ENV=="production") ? "myail-eyaaa-aaaag-alluq-cai" : "bkyz2-fmaaa-aaaaa-qaaaq-cai";
const DFX_NETWORK = (true || process.env.NODE_ENV=="production") ? "https://icp-api.io" : "http://127.0.0.1:4943";
  
$(document).ready(async function() {
    
  ons.ready(async function() {
    	if(DEBUG) console.log("Onsen ready");
    	ons.disableDeviceBackButtonHandler();

      const authClient = await AuthClient.create();
      const isAuthenticated = await authClient.isAuthenticated();
      
  		if(isAuthenticated) {
        const templateName = "footerMenuTemplate";
        document.querySelector('#myNavigator').pushPage(templateName);
        window.history.pushState({page: templateName}, templateName);
  		} else {
        const templateName = "loginTemplate";
        document.querySelector('#myNavigator').pushPage(templateName);
        window.history.pushState({page: templateName}, templateName);
		}	  
  });

});

// On page init
document.addEventListener('init', function(event) {
	var page = event.target;
	if(page.id=="") return;
	if(DEBUG) console.log("Init event for page: "+page.id);	
	switch(page.id) {
		case 'loginPage':
			initializeLoginPage(page);
			break;
    case 'footerMenuPage':
      initializeFooterMenuPage(page);
      break;
		case 'accountPage':
			initializeAccountPage(page);
			break;
    case 'editAccountPage':
      initializeEditAccountPage(page);
      break;
    case 'editContractPage':
        initializeEditContractPage(page);
        break;
    case 'viewContractPage':
			initializeViewContractPage(page);
			break;
    case 'viewContractChangesPage':
      initializeViewContractChangesPage(page);
      break;
    case 'shareContractPage':
      initializeShareContractPage(page);
      break;
    case 'addContractPage':
      initializeAddContractPage(page);
      break;
	}		
});
// On page show
document.addEventListener('show', function(event) {
  var page = event.target;
	if(page.id=="") return;
	if(DEBUG) console.log("Showing page: "+page.id);
	var pageElement = $("#"+page.id).localize();
	switch(page.id) {
		case 'loginPage':
			break;
	}
});
// On page hide
document.addEventListener('hide', function(event) {
	var page = event.target;
	if(page.id=="") return;
	if(DEBUG) console.log("Hiding page: "+page.id);
	switch(page.id) {
		case 'loginPage':
			break;
	}
});
// On page destroy
document.addEventListener('destroy', function(event) {
	var page = event.target;
	if(page.id=="") return;
	if(DEBUG) console.log("Destroying page: "+page.id);
	switch(page.id) {
		case 'loginPage':
			break;
	}
});

// footerMenuPage
async function initializeFooterMenuPage(page) {
	if(DEBUG) console.log("Initializing footerMenuPage...");
	var pageElement = $("#"+page.id).localize();
  new ResizeObserver(function(){
    //$("#footerMenuPage").find(".page__content").height(window.innerHeight - 56);
  }).observe(pageElement.find(".page__content")[0]);

  initializeNavdrawer();
  
  var flag = (LANG=="en") ? "gb" : LANG;
  $("[data-target='#languageModal']").find(".flag").removeClass(function(index, className) {
    return (className.match(/\bflag-icon-\S+/g) || []).join(' ');
  }).addClass("flag-icon-"+flag);

  var uid = getParameterByName(window.location.href, "sharedContract");
  if(typeof uid!=='undefined' && uid!==null) {
    const templateName = "addContractTemplate";
    ons.createDialog(templateName, { append: true }).then(function(dialog) {
        $(dialog).attr("data-uid", uid);
        dialog.show();
        window.history.pushState({page: templateName}, templateName);
    });
  }
}
function initializeNavdrawer() {
  if(DEBUG) console.log("Initializing navdrawer...");

  $("#navdrawer").find('#logoutBtn').off("click").on("click", async function() {
    if(DEBUG) console.log("Click on logoutBtn...");
    const authClient = await AuthClient.create();
    await authClient.logout();
    window.location.href = '/';
  });
}
// loginPage
async function initializeLoginPage(page) {
	if(DEBUG) console.log("Initializing login page...");
	var pageElement = $("#"+page.id).localize();
	
	const authClient = await AuthClient.create();
   
  pageElement.find('#loginBtn').on("click", async function() {
    if(DEBUG) console.log("Click on loginBtn...");
    authClient.login({
      identityProvider: "https://identity.ic0.app/#authorize",
        onSuccess: async function() {
          console.log("Login callback");
          window.location.reload();
        }
    });
  });
}
// accountPage
async function initializeAccountPage(page) {
	if(DEBUG) console.log("Initializing accountPage...");
	var pageElement = $("#"+page.id).localize();
  getViewAccount();
  var pullHook = page.querySelector('[id=pullHook]');
  pullHook.addEventListener('changestate', onPullHookChangeState);
  pullHook.onAction = function(done) {
    getViewAccount(done);
  };
  pageElement.find("#addContractBtn").off("click").on("click", function(){
    console.log("Click on addContractCardBtn...");
    pageElement.find("#createContractCard").slideToggle();
  });

  pageElement.find("#createContractBtn").off("click").on("click", function(e){
    e.preventDefault();
    console.log("Click on createContractBtn...");
    createContract();
  });

  pageElement.find('#editAccountBtn').off("click").on("click", async function() {
    console.log("Click on editAccountBtn...");
    var templateName = "editAccountTemplate";
    document.querySelector('#myNavigator').pushPage(templateName);
    window.history.pushState({page: templateName}, templateName);
  });
}
async function getViewAccount(done) {
  if(DEBUG) console.log("Getting account...");
	var pageElement = $("#accountPage");
  var content = pageElement.find("#content");
  var progressCircular = pageElement.find("#progressCircular");
  content.hide();
  progressCircular.show();

	const authClient = await AuthClient.create();
  const isAuthenticated = await authClient.isAuthenticated();
  const principal = authClient.getIdentity().getPrincipal().toText();
  setCookie("principal", principal, 365);

  pageElement.find("#accountCard").find("#principal").text(principal);

  getContracts(principal).then((contracts) => {
    if(contracts!==null && contracts.length>0) {
      content.find("#contractList").empty();
      contracts.forEach(contract => {
        var html = "";
        html += "<div class='list-group-item list-group-item-action contract' uid='"+contract.uid+"'>";
        html += "<div class='info'>"
        html += "<div class='name'>"+contract.name+"</div>";
        if(contract.sellerId==principal) {
          html += "<div class='role'>"+i18next.t("accountPage.Seller")+"</div>";
        } else if(contract.buyerId==principal) {
          html += "<div class='role'>"+i18next.t("accountPage.Buyer")+"</div>";
        }
        html += "</div>";
        html += "<div class='btns'>";
        html += "<button class='btn viewBtn'><span class='material-symbols-outlined icon'>visibility</span></button>";
        html += "<button class='btn editBtn'><span class='material-symbols-outlined icon'>edit</span></button>";
        html += "<button class='btn deleteBtn'><span class='material-symbols-outlined icon'>delete</span></button>";
        html += "<button class='btn shareBtn'><span class='material-symbols-outlined icon'>share</span></button>";
        html += "</div>";
        html += "</div>";
        $("#contractList").append(html);
      });
      content.find("#noContracts").hide();
      content.find("#contractList").show();
      content.find("#contractList").find(".contract").off("click").on("click", function(e){
        if(DEBUG) console.log("Click on contract...");
        const uid = $(this).attr("uid");
        var templateName = "";
        if($(e.target).closest(".btn").hasClass("viewBtn")) {
          templateName = "viewContractTemplate";
        } else if($(e.target).closest(".btn").hasClass("editBtn")) {
          templateName = "editContractTemplate";
        } else if($(e.target).closest(".btn").hasClass("deleteBtn")) {
          $(this).prop("disabled", true);
          deleteContract(uid).then((contract) => {
            getAccount();
          }).catch((error) => {
            showToast("Error deleting contract on database.");
            $(this).prop("disabled", false);
          });
        } else if($(e.target).closest(".btn").hasClass("shareBtn")) {
          templateName = "shareContractTemplate";
        } else {
          templateName = "viewContractTemplate";
        }
        if(templateName!=="") {
          document.querySelector('#myNavigator').pushPage(templateName, {data: {uid: uid}});
          window.history.pushState({page: templateName}, templateName);
        }
      });
    } else {
      content.find("#noContracts").show();
      content.find("#contractList").hide();
    }
    content.show();
    progressCircular.hide();
    if(done) done();
  }).catch((error) => {
    if(DEBUG) console.error('Error:', error);
    if(done) done();
  });

}
async function createContract() {
  if(DEBUG) console.log("Creating contract...");
	var pageElement = $("#accountPage");
  var form = pageElement.find("#createContractForm");
  if(form[0].checkValidity() === false) {
    form.addClass('was-validated');
    return;
  }
  const time = Math.floor(Date.now()/1000);
  const principal = getCookie("principal");
  const contract = {
    uid: uuidv4(),
    name: form.find("input[name=name]").val(),
    data: null,
    buyerId: (form.find("select[name=role]").val()=="buyer") ? principal : null,
    sellerId: (form.find("select[name=role]").val()=="seller") ? principal : null,
    created: time,
    createdBy: principal,
    updated: time,
    updatedBy: principal
  };

  postContract(contract).then((contract) => {
    pageElement.find("#addContractBtn").trigger("click");
    getViewAccount();
  }).catch((error) => {
    showToast("Error saving contract on database.");
  });

}
// editAccountPage
async function initializeEditAccountPage(page) {
	if(DEBUG) console.log("Initializing editAccountPage...");
	var pageElement = $("#"+page.id).localize();
  getEditAccount();
  var pullHook = page.querySelector('[id=pullHook]');
  pullHook.addEventListener('changestate', onPullHookChangeState);
  pullHook.onAction = function(done) {
    getEditAccount(done);
  };
  pageElement.find("#backBtn").off("click").on("click", function(){
    if(DEBUG) console.log("Click on backBtn...");
    document.querySelector('#myNavigator').popPage();
  });
  pageElement.find("#saveBtn").off("click").on("click", function(e){
    if(DEBUG) console.log("Click on saveBtn...");
    e.preventDefault();
    const form = pageElement.find("#editAccountForm");
    const time = Math.floor(Date.now()/1000);
    const principal = getCookie("principal");
    const account = {
      uid: form.find("input[name=uid]").val() || uuidv4(),
      id: principal,
      name: form.find("input[name=name]").val(),
      surname: form.find("input[name=surname]").val(),
      email: form.find("input[name=email]").val(),
      phone: form.find("input[name=phone]").val(),
      created: time,
      createdBy: principal,
      updated: time,
      updatedBy: principal
    };

    postAccount(account).then((account) => {
      document.querySelector('#myNavigator').popPage();
      showToast("Informazioni aggiornate!");
    }).catch((error) => {
      showToast("Error saving account on database.");
    });
  });
}
function getEditAccount(done) {
	if(DEBUG) console.log("Getting editAccount...");
  var pageElement = $("#editAccountPage");
  var content = pageElement.find("#content");
  var progressCircular = pageElement.find("#progressCircular");
  content.hide();
  progressCircular.show();

  const principal = getCookie("principal");
  
  getAccount(principal).then((account) => {
    const form = pageElement.find("#editAccountForm");
    form.find("input[name=uid]").val(account.uid);
    form.find("input[name=name]").val(account.name);
    form.find("input[name=surname]").val(account.surname);
    form.find("input[name=email]").val(account.email);
    form.find("input[name=phone]").val(account.phone);
    content.show();
    progressCircular.hide();
    if(done) done();
  }).catch((error) => {
    showToast("Error");
    if(done) done();
  });
}
// editContractPage
function initializeEditContractPage(page) {
	if(DEBUG) console.log("Initializing editContractPage...");
	var pageElement = $("#"+page.id).localize();

  const countries = i18next.t('countries', { returnObjects: true });
  for(let [code, country] of Object.entries(countries)) {
    pageElement.find("select[name='sellerCountry']").append("<option value='"+code+"'>"+country+"</option>");
    pageElement.find("select[name='buyerCountry']").append("<option value='"+code+"'>"+country+"</option>");
    pageElement.find("select[name='shippingCountry']").append("<option value='"+code+"'>"+country+"</option>");
  }

  getEditContract(page.data.uid);
  var pullHook = page.querySelector('[id=pullHook]');
  pullHook.addEventListener('changestate', onPullHookChangeState);
  pullHook.onAction = function(done) {
    getEditContract(page.data.uid, done);
  };
  pageElement.find("#backBtn").off("click").on("click", function(){
    if(DEBUG) console.log("Click on backBtn...");
    document.querySelector('#myNavigator').popPage();
  });
}
function getEditContract(uid, done) {
	if(DEBUG) console.log("Getting editContract...");
  var pageElement = $("#editContractPage");
  var content = pageElement.find("#content");
  var progressCircular = pageElement.find("#progressCircular");
  content.hide();
  progressCircular.show();
  
  getContract(uid).then((contract) => {
    const form = pageElement.find("#editContractForm");

    if(contract.data!==null) {
      var formData = JSON.parse(contract.data);

      const itemCodes = Array.isArray(formData["itemCode[]"]) ? formData["itemCode[]"] : [formData["itemCode[]"]];
      const itemDescriptions = Array.isArray(formData["itemDescription[]"]) ? formData["itemDescription[]"] : [formData["itemDescription[]"]];
      const itemQuantities = Array.isArray(formData["itemQuantity[]"]) ? formData["itemQuantity[]"] : [formData["itemQuantity[]"]];
      const itemPrices = Array.isArray(formData["itemPrice[]"]) ? formData["itemPrice[]"] : [formData["itemPrice[]"]];
      const itemCurrencies = Array.isArray(formData["itemCurrency[]"]) ? formData["itemCurrency[]"] : [formData["itemCurrency[]"]];
      const itemOrigins = Array.isArray(formData["itemOrigin[]"]) ? formData["itemOrigin[]"] : [formData["itemOrigin[]"]];
      const itemHScodes = Array.isArray(formData["itemHScode[]"]) ? formData["itemHScode[]"] : [formData["itemHScode[]"]];
      
      for(let i=0; i<itemCodes.length; i++) {
        if(i==0) {
          form.find("input[name='itemCode[]']").val(itemCodes[i]); 
          form.find("input[name='itemDescription[]']").val(itemDescriptions[i]);
          form.find("input[name='itemQuantity[]']").val(itemQuantities[i]);
          form.find("input[name='itemPrice[]']").val(itemPrices[i]);
          form.find("select[name='itemCurrency[]']").val(itemCurrencies[i]);
          form.find("input[name='itemOrigin[]']").val(itemOrigins[i]);
          form.find("input[name='itemHScode[]']").val(itemHScodes[i]);
        } else {
          var clone = form.find(".itemRow:first-child").clone();
          clone.find("input[name='itemCode[]']").val(itemCodes[i]); 
          clone.find("input[name='itemDescription[]']").val(itemDescriptions[i]);
          clone.find("input[name='itemQuantity[]']").val(itemQuantities[i]);
          clone.find("input[name='itemPrice[]']").val(itemPrices[i]);
          clone.find("select[name='itemCurrency[]']").val(itemCurrencies[i]);
          clone.find("input[name='itemOrigin[]']").val(itemOrigins[i]);
          clone.find("input[name='itemHScode[]']").val(itemHScodes[i]);
          clone.appendTo("#itemRows");
        }
      }  
    
      $.each(formData, function(key, value){
        if(key=="itemCode[]" || key=="itemDescription[]" || key=="itemQuantity[]" || key=="itemPrice[]" || key=="itemCurrency[]" || key=="itemHScode[]" || key=="itemOrigin[]") return;
        var inputElement = form.find("[name='"+key+"']");
        if(inputElement.is(":checkbox")) {
          inputElement.prop("checked", value);
        } else if(inputElement.is(":radio")) {
          form.find("input[name='"+key+"'][value='"+value+"']").prop("checked", value);
        } else {
          inputElement.val(value);
        }
      });
    }
    form.find("select[name=shippingTermId]").find("option").hide();
    form.find("select[name=shippingTermCatalogId]").off("change").on("change", function(e){
        e.preventDefault();
        if(DEBUG) console.log("Change on shippingTermCatalogId...");
        const shippingTermCatalogId = form.find("select[name=shippingTermCatalogId]").val();
        form.find("select[name=shippingTermId]").val("");
        form.find("select[name=shippingTermId]").find("option").hide();
        form.find("select[name=shippingTermId]").find("option[shippingTermCatalogId='"+shippingTermCatalogId+"']").show();        
    });
    form.find("#addItemBtn").off("click").on("click", function(e){
        e.preventDefault();
        if(DEBUG) console.log("Click on addItemBtn...");
        var clone = form.find(".itemRow:first-child").clone();
        clone.find("input").val("");
        clone.appendTo("#itemRows");
        form.keyup();
    });
    form.off("keyup").on("keyup", function(){
        if(DEBUG) console.log("Keyup on contractForm...");
        refreshEditContractForm();
    });
    form.off("click").on("click", function(e){
      if(DEBUG) console.log("Click on contractForm...");
      refreshEditContractForm();
      if($(e.target).closest(".removeItemBtn").length) {
        if(DEBUG) console.log("Click on removeItemBtn...");
        e.preventDefault();
        if(form.find(".itemRow").length>1) {
          $(e.target).closest(".itemRow").remove();
        }
      }
    });
    form.find("#saveBtn").off("click").on("click", async function(e) {
      e.preventDefault();
      if(DEBUG) console.log("Click on saveBtn...");
      contract.data = JSON.stringify(formToJson(form[0]));
      postContract(contract).then((contract) => {
        const templateName = "viewContractTemplate";
        document.querySelector('#myNavigator').replacePage(templateName, {data: {uid: uid}});
        window.history.replaceState({page: templateName}, templateName);
      });
   });
   content.show();
   progressCircular.hide();
   if(done) done();
   setTimeout(function(){
    introJs()./*setOption("dontShowAgain", true).*/start();
   }, 1000);
  }).catch((error) => {
    showToast("Error: "+error);
    if(done) done();
  });
}
function refreshEditContractForm() {
  if(DEBUG) console.log("Refresh editContractForm...");
  const pageElement = $("#editContractPage");
  const form = pageElement.find("#editContractForm");
   // productExportAlert
   var showProductExportAlert = false;
   form.find("input[name='itemCode[]']").each(function(){
       if($(this).val()=="XX" && form.find("select[name=buyerCountry]").val()=="IT") {
           showProductExportAlert = true;
       }
   });
   if(showProductExportAlert) {
       form.find("#productExportAlert").show();
   } else {
       form.find("#productExportAlert").hide();
   }
   // items and amount
   var amount = 0;
   form.find(".itemRow").each(function(){
      $(this).find("[data-toggle=collapse]").attr("href", "#collapseItem"+$(this).index());
      $(this).find(".collapse").attr("id", "collapseItem"+$(this).index());
      // itemName
     if($(this).find("input[name='itemDescription[]']").val()!=="") {
       $(this).find(".itemName").text($(this).find("input[name='itemDescription[]']").val());
     } else {
       $(this).find(".itemName").text(i18next.t("A1.Unnamed_product"));
     }
     // amount
     if($(this).find("input[name='itemQuantity[]']").val()!=="" && $(this).find("input[name='itemPrice[]']").val()!=="") {
       var itemQuantity = parseInt($(this).find("input[name='itemQuantity[]']").val());
       var itemPrice = parseFloat($(this).find("input[name='itemPrice[]']").val());
       var itemAmount = itemQuantity * itemPrice;
       $(this).find(".itemAmount").text(itemAmount.toFixed(2)+" €");
       amount += itemAmount;
     } else {
       $(this).find(".itemAmount").text("");
     }
   });
   form.find("input[name='amount']").val(amount);
   // A7
   form.find("#delayedPaymentDiv").toggle(form.find("input[name='payment']:checked").val()=="delayed");
   form.find("#prepaymentDiv").toggle(form.find("input[name='payment']:checked").val()=="prepayment");
   form.find("#paymentAgainstDocumentsDiv").toggle(form.find("input[name='payment']:checked").val()=="againstDocuments");
   form.find("#otherPaymentDiv").toggle(form.find("input[name='payment']:checked").val()=="other");
   form.find("#irrevocableDocumentaryCreditDiv").toggle(form.find("input[name='irrevocableDocumentaryCredit']").is(":checked"));
   // A8
   form.find("#otherDocumentDiv").toggle(form.find("input[name='otherDocument']").is(":checked"));
   // A10
   form.find("#lateDeliveryPenaltyDiv").toggle(form.find("input[name='lateDeliveryPenalty']").is(":checked"));
   form.find("#lateDeliverySellerResponsabilityDiv").toggle(form.find("input[name='lateDeliverySellerResponsabilityEnabled']").is(":checked"));
   form.find("#sellerResponsabilityDiv").toggle(form.find("input[name='sellerResponsabilityEnabled']").is(":checked"));
   // A11
   form.find("#priceReductionPercentageDiv").toggle(form.find("input[name='priceReduction']:checked").val()=="percentage");
   form.find("#priceReductionAmountDiv").toggle(form.find("input[name='priceReduction']:checked").val()=="value");
   form.find("#priceReductionOtherDiv").toggle(form.find("input[name='priceReduction']:checked").val()=="other");
   // A12
   form.find("#exceedPercentageDiv").toggle(form.find("input[name='exceedPercentageEnabled']").is(":checked"));
   form.find("#exceedAmountDiv").toggle(form.find("input[name='exceedAmountEnabled']").is(":checked")); 
   // A15
   form.find("#disputeResolutionOtherDiv").toggle(form.find("select[name='disputeResolution']").val()=="other");
   form.find("#disputeResolutionLitigationDiv").toggle(form.find("select[name='disputeResolution']").val()=="litigation");
}
// viewContractPage
function initializeViewContractPage(page) {
	if(DEBUG) console.log("Initializing viewContractPage...");
	var pageElement = $("#"+page.id).localize();
  getViewContract(page.data.uid);
  var pullHook = page.querySelector('[id=pullHook]');
  pullHook.addEventListener('changestate', onPullHookChangeState);
  pullHook.onAction = function(done) {
    getViewContract(page.data.uid, done);
  };
  pageElement.find("#backBtn").off("click").on("click", function(){
    if(DEBUG) console.log("Click on backBtn...");
    document.querySelector('#myNavigator').popPage();
  });
  pageElement.find("#viewContractChangesBtn").off("click").on("click", function(){
    if(DEBUG) console.log("Click on viewContractChangesBtn...");
    const templateName = "viewContractChangesTemplate";
    document.querySelector('#myNavigator').pushPage(templateName, {data: {uid: page.data.uid}});
    window.history.pushState({page: templateName}, templateName);
  });
}
function getViewContract(uid, done) {
	if(DEBUG) console.log("Getting contract...");
  var pageElement = $("#viewContractPage");
  var content = pageElement.find("#content");
  var progressCircular = pageElement.find("#progressCircular");
  content.hide();
  progressCircular.show();
  getContract(uid).then((contract) => {
      pageElement.find("#backToolbar").find(".center").removeAttr("data-i18n").text(contract.name);
      // Contract amount
      const data = JSON.parse(contract.data);
      const amount =  (data!==null && data["amount"]!==null) ? parseFloat(data["amount"]).toFixed(2) : 0;
      // Contract status
      pageElement.find(".statusDiv").hide();
      pageElement.find(".trackingDiv").find(".step").removeClass("done");    
      if(contract.status==null) {
        pageElement.find(".statusDiv#draft").show();

        pageElement.find("#storeContractBtn").off("click").on("click", function(){
          if(DEBUG) console.log("Click on storeContractBtn...");
          storeContract(uid);
        });
      } else if(contract.status=="stored") {
        pageElement.find(".statusDiv#stored").show();
        pageElement.find(".trackingDiv").find(".step#stored").addClass("done").addClass("active");
        if(contract.sellerId==getCookie("principal") && contract.sellerSignature!==null) {
          pageElement.find("#signContractBtn").prop("disabled", true);
        } else if(contract.buyerId==getCookie("principal") && contract.buyerSignature!==null) {
          pageElement.find("#signContractBtn").prop("disabled", true);
        }
        pageElement.find("#signContractBtn").off("click").on("click", function(){
          if(DEBUG) console.log("Click on signContractBtn...");
          signContract(uid);
        });
      } else if(contract.status=="signed") {
        pageElement.find(".statusDiv#signed").show();
        pageElement.find(".trackingDiv").find(".step#stored").addClass("done");
        pageElement.find(".trackingDiv").find(".step#signed").addClass("done").addClass("active");
      } else if(contract.status=="shipped") {
        pageElement.find(".statusDiv#shipped").show();
        pageElement.find(".trackingDiv").find(".step#stored").addClass("done");
        pageElement.find(".trackingDiv").find(".step#signed").addClass("done");
        pageElement.find(".trackingDiv").find(".step#shipped").addClass("done").addClass("active");
      } else if(contract.status=="delivered") {
        pageElement.find(".statusDiv#delivered").show();
        pageElement.find(".trackingDiv").find(".step#stored").addClass("done");
        pageElement.find(".trackingDiv").find(".step#signed").addClass("done");
        pageElement.find(".trackingDiv").find(".step#shipped").addClass("done");
        pageElement.find(".trackingDiv").find(".step#delivered").addClass("done").addClass("active");
      } else if(contract.status=="completed") {
        pageElement.find(".statusDiv#completed").show();
        pageElement.find(".trackingDiv").find(".step#stored").addClass("done");
        pageElement.find(".trackingDiv").find(".step#signed").addClass("done");
        pageElement.find(".trackingDiv").find(".step#shipped").addClass("done");
        pageElement.find(".trackingDiv").find(".step#completed").addClass("done");
      }
      // Payment status
      if(contract.status==null || contract.status=="stored") {
        pageElement.find(".paymentStatusDiv").find(".step#buyer").addClass("done").addClass("active");
        pageElement.find(".paymentStatusDiv").find(".step#buyer").find(".amount").text(amount+" €");
      } else if(contract.status=="signed" || contract.status=="shipped") {
        pageElement.find(".paymentStatusDiv").find(".step#buyer").addClass("done");
        pageElement.find(".paymentStatusDiv").find(".step#contract").addClass("done").addClass("active");
        pageElement.find(".paymentStatusDiv").find(".step#contract").find(".amount").text(amount+" €");
      } else if(contract.status=="delivered" || contract.status=="completed") {
        pageElement.find(".paymentStatusDiv").find(".step#buyer").addClass("done");
        pageElement.find(".paymentStatusDiv").find(".step#contract").addClass("done");
        pageElement.find(".paymentStatusDiv").find(".step#seller").addClass("done");
        pageElement.find(".paymentStatusDiv").find(".step#seller").find(".amount").text(amount+" €");
      }
      // Contract details
      pageElement.find("#uid").text(contract.uid);
      pageElement.find("#name").text(contract.name);
      pageElement.find("#model").text("ICC");
      pageElement.find("#amount").text(amount+" €");
      if(contract.sellerId!==null) {
        pageElement.find("#sellerId").text(contract.sellerId);
      }
      if(contract.buyerId!==null) {
        pageElement.find("#buyerId").text(contract.buyerId);
      }
      if(contract.sellerSignature!==null) {
        pageElement.find("#sellerSignature").text(moment(contract.sellerSignature*1000).format('llll'));
      }
      if(contract.buyerSignature!==null) {
        pageElement.find("#buyerSignature").text(moment(contract.buyerSignature*1000).format('llll'));
      }
      if(contract.id!==null) {
        pageElement.find("#id").text(contract.id);
        pageElement.find("#verifyLink").show();
      } else {
        pageElement.find("#verifyLink").hide();
      }
      // PDF btn
      pageElement.find("#openPdfBtn").off("click").on("click", function(){
        if(DEBUG) console.log("Click on openPdfBtn...");
        window.open(APP_URL+"/api/v1/contracts/"+uid+"/pdf?lang="+LANG, "_blank");
      });
      content.show();
      progressCircular.hide();
      if(done) done();
  }).catch((error) => {
    if(DEBUG) console.error('Error:', error);
    showToast("Error");
    if(done) done();
  });
}
async function storeContract(uid) {
  if(DEBUG) console.log("Storing contract...");
  var pageElement = $("#viewContractPage");
  var btn = pageElement.find("#storeContractBtn");
  btn.prop("disabled", true);

  getContract(uid).then(async (contract) => {

    if(contract.sellerId==null) {
      showToast("Seller not defined.");
      btn.prop("disabled", false);
      return;
    }

    if(contract.buyerId==null) {
      showToast("Buyer not defined.");
      btn.prop("disabled", false);
      return;
    }

    console.log("SONO QUI");
    const publicKeyBase64 = fs.readFileSync('./.keys/public.key', 'utf8');
    const publicKey = await importPublicKey(publicKeyBase64);
    const CONTRACT_JSON = contract.data; //await encryptLargeJSON(contract.data, publicKey);
    const SELLER_PRINCIPAL = contract.sellerId;
    const BUYER_PRINCIPAL = contract.buyerId;
    const backendCanister  = createActor(CANISTER_ID, { agentOptions:{host: DFX_NETWORK}}); //, identity: }});
    const result = await backendCanister
      .create_contract(CONTRACT_JSON, Principal.fromText(BUYER_PRINCIPAL), Principal.fromText(SELLER_PRINCIPAL))
      .catch(function(){
        console.log("catch");
      });
    console.log(result);
  
    if(parseInt(result)>0){
      contract.id = parseInt(result);
      contract.status = "stored";
      contract.stored = Math.floor(Date.now()/1000);
      postContract(contract).then((contract) => {
        getViewContract(uid);
      });
    } else {
      showToast("Error storing contract on ICP chain.");
      btn.prop("disabled", false);
    }
  }).catch((error) => {
    console.error('Error:', error);
    showToast("Error");
  });
}
async function signContract(uid) {
  if(DEBUG) console.log("Sign contract...");
  var pageElement = $("#editContractPage");
  var form = pageElement.find("#editContractForm");
  var btn = pageElement.find("#signContractBtn");
  btn.prop("disabled", true);

  const authClient = await AuthClient.create();
  const identity = await authClient.getIdentity();
  const principal = authClient.getIdentity().getPrincipal().toText();
  
  getContract(uid).then(async (contract) => {
    const backendCanister  = createActor(CANISTER_ID, { agentOptions:{host: DFX_NETWORK, identity: identity} });
    const result = await backendCanister
      .sign_contract(contract.id)
      .then(function(){
        const time = Math.floor(Date.now()/1000);
        if(principal==contract.sellerId) {
          contract.sellerSignature = time;
        } else if(principal==contract.buyerId) {
          contract.buyerSignature = time;
        }
        if(contract.sellerSignature!==null && contract.buyerSignature!==null) {
          contract.status = "signed";
          contract.signed = time;
        }
        postContract(contract).then((contract) => {
          getViewContract(uid);
        });
      })
      .catch(function(e){
        console.log("catch", e);
        showToast("Error signing contract on ICP chain.");
        btn.prop("disabled", false);
      });
    console.log(result);
  }).catch((error) => {
    console.error('Error:', error);
    showToast("Error");
  });
  
}
// viewContractChangesPage
function initializeViewContractChangesPage(page) {
	if(DEBUG) console.log("Initializing viewContractChangesPage...");
	var pageElement = $("#"+page.id).localize();
  getViewContractChanges(page.data.uid);
  var pullHook = page.querySelector('[id=pullHook]');
  pullHook.addEventListener('changestate', onPullHookChangeState);
  pullHook.onAction = function(done) {
    getViewContractChanges(page.data.uid, done);
  };
  pageElement.find("#backBtn").off("click").on("click", function(){
    if(DEBUG) console.log("Click on backBtn...");
    document.querySelector('#myNavigator').popPage();
  });
}
function getViewContractChanges(uid, done) {
	if(DEBUG) console.log("Getting contractChanges...");
  var pageElement = $("#viewContractChangesPage");
  var content = pageElement.find("#content");
  var progressCircular = pageElement.find("#progressCircular");
  content.hide();
  progressCircular.show();
  getContractChanges(uid).then((contractChanges) => {
    console.log(contractChanges);
      content.find("#contractChangesListGroup").empty();
      var contractChangeGroupId = null;
      contractChanges.forEach(contractChange => {
        if(contractChangeGroupId!==contractChange.created) {
          contractChangeGroupId = contractChange.created;
          var role = (contractChange.contractRole=="seller") ? "Venditore" : "Compratore";
          var html = "";
          html += "<div class='expansion-panel list-group-item contractChangeGroup'>";
          html += "<a class='expansion-panel-toggler collapsed' data-toggle='collapse' href='#collapse"+contractChangeGroupId+"'>";
          html += "<div>";
          html += "<div class='who'>"+role+"</div>";
          html += "<div class='when'>"+moment(contractChange.created*1000).format("llll")+"</div>";
          html += "</div>";
          html += "<div class='expansion-panel-icon ml-3 text-black-secondary'>";
          html += "<i class='collapsed-show material-icons'>keyboard_arrow_down</i>";
          html += "<i class='collapsed-hide material-icons'>keyboard_arrow_up</i>";
          html += "</div>";
          html += "</a>";
          html += "<div class='collapse' id='collapse"+contractChangeGroupId+"'>";
          html += "<div class='expansion-panel-body'>";
          html += "</div>";
          html += "</div>";
          html += "</div>";
          content.find("#contractChangesListGroup").append(html);
        }
        var html = "";
        html += "<div class='list-group-item contractChange'>";
        if(contractChange.oldValue=="") {
          html += "<div>Valorizzazione del campo <span class='fieldName'>"+contractChange.fieldName+"</span></div>";
          html += "<div><span class='newValue'>"+contractChange.newValue+"</span></div>";
        } else if(contractChange.newValue=="") {
          html += "<div>Azzeramento del campo <span class='fieldName'>"+contractChange.fieldName+"</span></div>";
          html += "<div><span class='oldValue'>"+contractChange.oldValue+"</span></div>";
        } else {
          html += "Modifica del campo <span class='fieldName'>"+contractChange.fieldName+"</span>";
          html += "<div><span class='oldValue'>"+contractChange.oldValue+"</span> -> <span class='newValue'>"+contractChange.newValue+"</span></div>";
        }
        html +=  "</div>";
        content.find("#contractChangesListGroup").find("#collapse"+contractChangeGroupId).find(".expansion-panel-body").append(html);
      });

      content.show();
      progressCircular.hide();
      if(done) done();
  }).catch((error) => {
    if(DEBUG) console.error('Error:', error);
    showToast("Error");
    if(done) done();
  });
}
// shareContractPage
function initializeShareContractPage(page) {
	if(DEBUG) console.log("Initializing shareContractPage...");
	var pageElement = $("#"+page.id).localize();
  getShareContract(page.data.uid);
  var pullHook = page.querySelector('[id=pullHook]');
  pullHook.addEventListener('changestate', onPullHookChangeState);
  pullHook.onAction = function(done) {
    getShareContract(page.data.uid, done);
  };
  pageElement.find("#backBtn").off("click").on("click", function(){
    if(DEBUG) console.log("Click on backBtn...");
    document.querySelector('#myNavigator').popPage();
  });
}
function getShareContract(uid, done) {
	if(DEBUG) console.log("Getting shareContract...");
  var pageElement = $("#shareContractPage");
  var content = pageElement.find("#content");
  var progressCircular = pageElement.find("#progressCircular");
  content.hide();
  progressCircular.show();

  const shareContractUrl = "https://app.tradeonchain.com?sharedContract="+uid;
  pageElement.find("#url").val(shareContractUrl);
  pageElement.find("#qrcode").find("img").attr("src", APP_URL+"/api/v1/qrcode?text="+encodeURI(shareContractUrl));
  
  const form = pageElement.find("#shareContractForm");
  form.find("#shareBtn").off("click").on("click", function(e){
		if(DEBUG) console.log("Click on shareBtn...");
		e.preventDefault();
		const shareMsg = form.find("#message").val();
		const shareUrl = form.find("#url").val();
		if(navigator.share) {
			navigator.share({
				text: shareMsg,
				url: shareUrl
			}).then(function(){
				if(DEBUG) console.log('Successful share');
			}).catch(function(error){
				if(DEBUG) console.error("Error sharing", error);
			});
		} else {
			navigator.clipboard.writeText(shareMsg+shareUrl);
			showToast("I dati per la condivisione sono stati copiati negli appunti, incollali dove preferisci.");
		}
	});
	pageElement.find("#copyBtn").off("click").on("click", function(e){
		e.preventDefault();
		if(DEBUG) console.log("Copy password to clipboard");
		/* Get the text field */
		var copyText = document.getElementById("url");  
		/* Select the text field */
		copyText.select();
		copyText.setSelectionRange(0, 99999); /*For mobile devices*/
		/* Copy the text inside the text field */
		document.execCommand("copy");
		pageElement.find("#url").tooltip('show');
		$(this).prop("disabled", true);
	});
  content.show();
  progressCircular.hide();
  if(done) done();
}
// addContractPage
function initializeAddContractPage(page) {
	if(DEBUG) console.log("Initializing addContractPage...");
	var pageElement = $("#"+page.id).localize();
  var dialogElement = pageElement.closest("ons-dialog");
  var uid = dialogElement.attr("data-uid");
  getAddContract(uid);
  pageElement.find("#backBtn, #cancelBtn").off("click").on("click", function(){
    if(DEBUG) console.log("Click on backBtn...");
    if(dialogElement.length) {
      dialogElement[0].hide().then(function() {
        if(DEBUG) console.log("Removing opened dialog...");
        dialogOpened[0].remove();
      });
    }
  });
}
function getAddContract(uid, done) {
	if(DEBUG) console.log("Getting addContract...");
  var pageElement = $("#addContractPage");
  var content = pageElement.find("#content");
  content.hide();

  getContract(uid).then((contract) => {
    if(DEBUG) console.info('Success');
      content.show();
      if(done) done();
      pageElement.find("#name").text(contract.name);

      pageElement.find("#addBtn").off("click").on("click", function(){
        if(DEBUG) console.log("Click on addBtn...");
        const time = Math.floor(Date.now()/1000);
        const principal = getCookie("principal");
        if(contract.sellerId==null) {
          contract.sellerId = principal;
        } else if(contract.buyerId==null) {
          contract.buyerId = principal;
        } else {
          showToast("Error: buyer and seller are already assigned to this contract.");
          return;
        }
        contract.updatedBy = principal;
        contract.updated = time;
        addContract(contract);
      });
  }).catch((error) => {
    if(DEBUG) console.error('Error:', error);
    showToast("Error");
  });
}
function addContract(contract) {
	if(DEBUG) console.log("Adding contract...");
  var pageElement = $("#addContractPage");
  postContract(contract).then((contract) => {
    pageElement.find("#backBtn").trigger("click");
    getViewAccount();
  }).catch((error) => {
    if(DEBUG) console.error('Error:', error);
    showToast("Error");
  });
}



// DB API
function getAccount(uidOrPrincipal) {
  return new Promise((resolve, reject) => {
    const url = APP_URL+'/api/v1/accounts/'+uidOrPrincipal;
    axios.get(url, {
    headers: {
      'Authorization': `Bearer ${BEARER_TOKEN}`,
      'Content-Type': 'application/json'
    }
    }).then(response => {
      if(DEBUG) console.log('Response:', response.data);
      if(response.data!==null) {
        resolve(response.data);
      } else {
        reject()
      }
    }).catch(error => {
      if(DEBUG) console.error('Error:', error);
      reject(error);
    }); 
});
}
function postAccount(requestBody) {
  return new Promise((resolve, reject) => {
    const url = APP_URL+'/api/v1/accounts';
    axios.post(url, requestBody, {
      headers: {
        'Authorization': `Bearer ${BEARER_TOKEN}`,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if(DEBUG) console.log('Response:', response.data);
      resolve(response);
    }).catch(error => {
      if(DEBUG) console.error('Error:', error);
      reject(error);
    });
});
}
function getContracts(principal) {
  return new Promise((resolve, reject) => { 
    const url = APP_URL+'/api/v1/contracts?principal='+principal;
    axios.get(url, {
    headers: {
      'Authorization': `Bearer ${BEARER_TOKEN}`,
      'Content-Type': 'application/json'
    }
    }).then(response => {
      if(DEBUG) console.log('Response:', response.data);
      resolve(response.data.data);
    }).catch(error => {
      if(DEBUG) console.error('Error:', error);
      reject(error);
    }); 
});
}
function getContract(uid) {
  return new Promise((resolve, reject) => {
    const url = APP_URL+'/api/v1/contracts/'+uid;
    axios.get(url, {
    headers: {
      'Authorization': `Bearer ${BEARER_TOKEN}`,
      'Content-Type': 'application/json'
    }
    }).then(response => {
      if(DEBUG) console.log('Response:', response.data);
      if(response.data!==null) {
        resolve(response.data);
      } else {
        reject()
      }
    }).catch(error => {
      if(DEBUG) console.error('Error:', error);
      reject(error);
    }); 
});
}
function getContractChanges(uid) {
  return new Promise((resolve, reject) => {
    const url = APP_URL+'/api/v1/contracts/'+uid+"/changes";
    axios.get(url, {
      headers: {
        'Authorization': `Bearer ${BEARER_TOKEN}`,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if(DEBUG) console.log('Response:', response.data);
      if(response.data!==null) {
        resolve(response.data);
      } else {
        reject()
      }
    }).catch(error => {
      if(DEBUG) console.error('Error:', error);
      reject(error);
    });
});
}
function postContract(requestBody) {
  return new Promise((resolve, reject) => {
    const url = APP_URL+'/api/v1/contracts';
    axios.post(url, requestBody, {
      headers: {
        'Authorization': `Bearer ${BEARER_TOKEN}`,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if(DEBUG) console.log('Response:', response.data);
      resolve(response);
    }).catch(error => {
      if(DEBUG) console.error('Error:', error);
      reject(error);
    });
});
} 
function deleteContract(uid) {
  return new Promise((resolve, reject) => {
    const url = APP_URL+'/api/v1/contracts/'+uid;
    axios.delete(url, {
      headers: {
        'Authorization': `Bearer ${BEARER_TOKEN}`,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if(DEBUG) console.log('Response:', response.data);
      if(response.data!==null) {
        const contract = response.data;
        resolve(contract);
      } else {
        reject()
      }
    }).catch(error => {
      if(DEBUG) console.error('Error:', error);
      reject(error);
    }); 
  });
}





function formToJson(form) {
  const formData = new FormData(form);
  const jsonObject = {};

  // Itera su tutti i campi del form
  formData.forEach((value, key) => {
    // Se il campo esiste già come chiave (es. checkbox con lo stesso name), crea un array
    if (jsonObject[key]) {
      if (!Array.isArray(jsonObject[key])) {
        jsonObject[key] = [jsonObject[key]];
      }
      jsonObject[key].push(value);
    } else {
      jsonObject[key] = value;
    }
  });

  return jsonObject;
}
function jsonToForm(json, form) {
  Object.keys(json).forEach(key => {
    const field = form.querySelector(`[name=${key}]`);

    // Verifica se il campo esiste nel form
    if (field) {
      field.value = json[key];
    }
  });
}



async function exampleUsage() {
  // Genera la coppia di chiavi
  const { publicKey, privateKey } = await generateKeyPair();

  // Esporta le chiavi in base64 per memorizzazione
  const publicKeyBase64 = await exportPublicKey(publicKey);
  const privateKeyBase64 = await exportPrivateKey(privateKey);

  console.log("Chiave pubblica (base64):", publicKeyBase64);
  console.log("Chiave privata (base64):", privateKeyBase64);

  // Importa le chiavi da base64
  const importedPublicKey = await importPublicKey(publicKeyBase64);
  const importedPrivateKey = await importPrivateKey(privateKeyBase64);

  // Test crittografia e decrittografia con le chiavi importate
  //const data = "Dati riservati del contratto";
  getContract("a74f7d0c-b154-42c0-b5cd-7393b8e856e3").then(async (contract) => {

    console.log(contract.data);
    console.log(JSON.parse(contract.data));
    const data = {"id":"","uid":"","sellerName":"Massimo Simoniello","sellerVatCode":"","sellerStreet":"Via Alfonso Marelli 32","sellerCountry":"it","sellerCity":"Monza"};//,"sellerProvince":"MB","sellerPostalCode":"20900"};//,"sellerLegalRepresentativeName":"test","sellerLegalRepresentativeSurname":"tes"};//,"sellerLegalRepresentativeEmail":"","sellerLegalRepresentativePrefix":"+39","sellerLegalRepresentativePhone":"","sellerContactName":"","sellerContactSurname":""};//,"sellerContactEmail":"","sellerContactPrefix":"+39","sellerContactPhone":"","buyerName":"","buyerVatCode":"","buyerStreet":"","buyerCountry":"it","buyerCity":"","buyerProvince":"","buyerPostalCode":"","buyerLegalRepresentativeName":""};//,"buyerLegalRepresentativeSurname":"","buyerLegalRepresentativeEmail":"","buyerLegalRepresentativePrefix":"+39","buyerLegalRepresentativePhone":"","buyerContactName":"","buyerContactSurname":"","buyerContactEmail":"","buyerContactPrefix":"+39","buyerContactPhone":""};
    console.log(data);
    const encryptedData = await encryptLargeJSON(data, publicKey);
  console.log("Dati criptati:", encryptedData);
  const decryptedData = await decryptLargeJSON(encryptedData, privateKey);
  console.log("Dati decriptati:", decryptedData)
  });
}
async function generateKeyPair() {
  const keyPair = await crypto.subtle.generateKey(
      {
          name: "RSA-OAEP",
          modulusLength: 2048,
          publicExponent: new Uint8Array([1, 0, 1]),
          hash: "SHA-256"
      },
      true,
      ["encrypt", "decrypt"]
  );
  return keyPair;
}
async function exportPublicKey(publicKey) {
  const exported = await crypto.subtle.exportKey("spki", publicKey);
  return Buffer.from(exported).toString("base64");  // Converte in base64 per memorizzazione
}
async function exportPrivateKey(privateKey) {
  const exported = await crypto.subtle.exportKey("pkcs8", privateKey);
  return Buffer.from(exported).toString("base64");  // Converte in base64 per memorizzazione
}
async function importPublicKey(publicKeyBase64) {
  const binary = Buffer.from(publicKeyBase64, "base64");
  return crypto.subtle.importKey(
      "spki",
      binary,
      { name: "RSA-OAEP", hash: "SHA-256" },
      true,
      ["encrypt"]
  );
}
async function importPrivateKey(privateKeyBase64) {
  const binary = Buffer.from(privateKeyBase64, "base64");
  return crypto.subtle.importKey(
      "pkcs8",
      binary,
      { name: "RSA-OAEP", hash: "SHA-256" },
      true,
      ["decrypt"]
  );
}
async function encryptJSONToBase64(jsonData, publicKey) {
  try {
      const jsonString = JSON.stringify(jsonData);
      const enc = new TextEncoder();
      const encodedData = enc.encode(jsonString);
      const encryptedData = await crypto.subtle.encrypt(
          { name: "RSA-OAEP" },
          publicKey,
          encodedData
      );
      return Buffer.from(encryptedData).toString("base64");
  } catch (error) {
      console.error("Errore durante la crittografia:", error);
      throw error;
  }
}
async function decryptBase64ToJSON(encryptedDataBase64, privateKey) {
  try {
      const encryptedDataBuffer = Buffer.from(encryptedDataBase64, "base64");
      const decryptedData = await crypto.subtle.decrypt(
          { name: "RSA-OAEP" },
          privateKey,
          encryptedDataBuffer
      );
      const dec = new TextDecoder();
      return JSON.parse(dec.decode(decryptedData));
  } catch (error) {
      console.error("Errore durante la decifratura:", error);
      throw error;
  }
}
async function encryptLargeJSON(jsonData, publicKey) {
  // Step 1: Convert JSON to string and then to ArrayBuffer
  const jsonString = JSON.stringify(jsonData);
  const enc = new TextEncoder();
  const encodedData = enc.encode(jsonString);

  // Step 2: Generate AES Key
  const aesKey = await crypto.subtle.generateKey(
      { name: "AES-GCM", length: 256 },
      true,
      ["encrypt", "decrypt"]
  );

  // Step 3: Encrypt JSON data with AES
  const iv = crypto.getRandomValues(new Uint8Array(12)); // Initialization vector
  const encryptedData = await crypto.subtle.encrypt(
      { name: "AES-GCM", iv },
      aesKey,
      encodedData
  );

  // Step 4: Export and Encrypt AES Key with RSA public key
  const exportedAesKey = await crypto.subtle.exportKey("raw", aesKey);
  const encryptedAesKey = await crypto.subtle.encrypt(
      { name: "RSA-OAEP" },
      publicKey,
      exportedAesKey
  );

  // Step 5: Encode results in base64 for easy transmission
  return {
      encryptedData: Buffer.from(encryptedData).toString("base64"),
      encryptedKey: Buffer.from(encryptedAesKey).toString("base64"),
      iv: Buffer.from(iv).toString("base64")
  };
}
async function decryptLargeJSON(encryptedObject, privateKey) {
  const { encryptedData, encryptedKey, iv } = encryptedObject;

  // Step 1: Decode Base64 values
  const encryptedDataBuffer = Buffer.from(encryptedData, "base64");
  const encryptedKeyBuffer = Buffer.from(encryptedKey, "base64");
  const ivBuffer = Buffer.from(iv, "base64");

  // Step 2: Decrypt AES Key with RSA private key
  const aesKeyRaw = await crypto.subtle.decrypt(
      { name: "RSA-OAEP" },
      privateKey,
      encryptedKeyBuffer
  );
  const aesKey = await crypto.subtle.importKey(
      "raw",
      aesKeyRaw,
      { name: "AES-GCM" },
      true,
      ["decrypt"]
  );

  // Step 3: Decrypt JSON data with AES
  const decryptedData = await crypto.subtle.decrypt(
      { name: "AES-GCM", iv: ivBuffer },
      aesKey,
      encryptedDataBuffer
  );

  const dec = new TextDecoder();
  return JSON.parse(dec.decode(decryptedData));
}




//exampleUsage();